<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import listPlugin from "@fullcalendar/list";

import { required } from "vuelidate/lib/validators";

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

// eslint-disable-next-line no-unused-vars
import { calendarEvents, categories } from "./data-calendar";
import i18n from "@/i18n";
import { meetingRoomBookingMethods, lookupMethods } from "@/state/helpers";
import { MEETING_ROOM_BOOKING_MODULE } from '@/state/modules/meetingRoomBooking'
import { LOOKUP_MODULE } from '@/state/modules/lookup'
import moment from "moment";
import { sessionHelper } from "@/utils";
// eslint-disable-next-line no-unused-vars
import { SESSION, SHARED } from "@/constants";
import { mapState } from "vuex";
import { sharedHelper } from '@/utils'
import Swal from "sweetalert2";

import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';


/**
 * Calendar component
 */
export default {
  page: {
    title: i18n.t("module.calendar"),
    meta: [{ name: "description" }],
  },
  components: { FullCalendar, Layout, PageHeader, datePicker },
  computed: {
    ...mapState(MEETING_ROOM_BOOKING_MODULE, ["status"]),
    ...mapState(LOOKUP_MODULE),
    roomDdl() {
      return this.$store.state.lookup.roomDdl;
    },
  },
  data() {
    return {
      title: i18n.t("module.calendar"),
      items: [
        {
          text: i18n.t("module.dashboard"),
          href: "/",
        },
        {
          text: i18n.t("module.booking"),
          active: true,
        },
      ],
      selectedEventId: "",
      options: {
        format: "HH:mm",
        stepping: 30,
        enabledHours: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18],
        icons: {
          time: "far fa-clock",
          date: "far fa-calendar",
          up: "fas fa-arrow-up",
          down: "fas fa-arrow-down",
          previous: "fas fa-chevron-left",
          next: "fas fa-chevron-right",
          today: "fas fa-calendar-check",
          clear: "far fa-trash-alt",
          close: "far fa-times-circle",
        },
      },
      optionsDate: {
        format: "DD/MM/YYYY",
        icons: {
          time: "far fa-clock",
          date: "far fa-calendar",
          up: "fas fa-arrow-up",
          down: "fas fa-arrow-down",
          previous: "fas fa-chevron-left",
          next: "fas fa-chevron-right",
          today: "fas fa-calendar-check",
          clear: "far fa-trash-alt",
          close: "far fa-times-circle",
        },
      },
      calendarOptions: {
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "custom1 dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        },
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
          bootstrapPlugin,
          listPlugin,
        ],
        slotMinTime: '08:00', // Set the start time to 8:00 AM
        initialEvents: this.$store.state.meetingRoomBooking.list,
        initialView: 'dayGridMonth',
        themeSystem: "bootstrap",
        editable: false,
        droppable: false,
        eventResizableFromStart: true,
        eventClick: this.editEvent,
        eventsSet: this.handleEvents,
        weekends: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        contentHeight: "auto",
        customButtons: {
          custom1: {
            text: 'Add',
            click: this.dateClicked,
          },
          prev: { // this overrides the prev button
            text: "PREV",
            click: async () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.prev();

              const view = calendarApi.view;
              const viewStartDate = view.currentStart;
              const viewEndDate = view.currentEnd;

              const startDateOfMonth = moment(viewStartDate).format("YYYY/MM/DD");
              const lastDateOfMonth = moment(viewEndDate).format("YYYY/MM/DD");

              await this.refreshBooking(startDateOfMonth, lastDateOfMonth);
            }
          },
          next: { // this overrides the next button
            text: "NEXT",
            click: async () => {
              let calendarApi = this.$refs.fullCalendar.getApi();
              calendarApi.next();

              const view = calendarApi.view;
              const viewStartDate = view.currentStart;
              const viewEndDate = view.currentEnd;

              const startDateOfMonth = moment(viewStartDate).format("YYYY/MM/DD");
              const lastDateOfMonth = moment(viewEndDate).format("YYYY/MM/DD");

              await this.refreshBooking(startDateOfMonth, lastDateOfMonth);
            }
          }
        }
      },
      currentEvents: [],
      calendarKey: "",
      showModal: false,
      eventModal: false,
      categories: categories,
      submitted: false,
      submit: false,
      newEventData: {},
      edit: {},
      deleteId: {},
      event: {
        title: "",
        date: null,
        start: null,
        end: null,
        detail: "",
      },
      editevent: {
        title: "",
        date: null,
        start: null,
        end: null,
        detail: "",
        id: ""
      },
    }
  },
  async created() {
    this.lookupMeetingRoom({ hasOptionAll: this.FALSE, id: 85 });

  },
  async mounted() {
    this.searchCriteria = sessionHelper.retrieveSearch(SESSION.MEETING_ROOM_SEARCH);
    await this.initSearchCriteria();
  },
  validations: {
    event: {
      title: { required },
      date: { required },
      start: { required },
      end: { required },
      id: { required },
    },
    editevent: {
      title: { required },
      date: { required },
      start: { required },
      end: { required },
      id: { required },
    },
  },
  methods: {
    ...meetingRoomBookingMethods,
    ...lookupMethods,
    async initSearchCriteria() {

      // Access FullCalendar API
      const calendarApi = this.$refs.fullCalendar.getApi();
      const view = calendarApi.view;
      const viewStartDate = view.currentStart;
      const viewEndDate = view.currentEnd;

      const startDateOfMonth = moment(viewStartDate).format("YYYY/MM/DD");
      const lastDateOfMonth = moment(viewEndDate).format("YYYY/MM/DD");

      if (sessionHelper.hasSearchCriteria(SESSION.MEETING_ROOM_SEARCH)) {
        this.searchCriteria = sessionHelper.retrieveSearch(SESSION.MEETING_ROOM_SEARCH);
      }

      this.searchCriteria = {
        companyId: 85,
        dateStart: startDateOfMonth,
        dateEnd: lastDateOfMonth,
        searchValue: "",
        searchArray: [],
      };

      await this.getBookingList(this.searchCriteria);
    },

    async getBookingList(filter, isSetEvent = true) {
      await this.getAllBooking({ filter });
      this.searchCriteria = filter;
      sessionHelper.storeSearch(SESSION.MEETING_ROOM_SEARCH, filter);
      if (isSetEvent == true) {
        this.calendarKey = "1";
        this.calendarOptions.events = this.$store.state.meetingRoomBooking.list.data;
      }
    },

    async refreshBooking(startDate, endDate) {
      if (sessionHelper.hasSearchCriteria(SESSION.MEETING_ROOM_SEARCH)) {
        this.searchCriteria = sessionHelper.retrieveSearch(SESSION.MEETING_ROOM_SEARCH);
      }

      this.searchCriteria = {
        companyId: 85,
        meetingRoomId: this.searchCriteria.selectedRecordId,
        dateStart: startDate,
        dateEnd: endDate,
        searchValue: "",
        searchArray: [],
        selectedRecordId: this.searchCriteria.selectedRecordId,
      };

      sessionHelper.storeSearch(SESSION.MEETING_ROOM_SEARCH, this.searchCriteria);
      await this.getAllBooking({ filter: this.searchCriteria, isSetEvent: false });
      this.calendarKey = '1';
      this.calendarOptions.events = this.$store.state.meetingRoomBooking.list.data;
      this.calendarOptions.initialEvents = this.$store.state.meetingRoomBooking.list.data;
    },

    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submitted = true;

      this.$v.$touch();
      if (this.$v.event.$invalid) {
        return;
      } else {
        var data = this.event;
        data.meetingRoomId = data['id'];
        this.showModal = false;
        this.newEventData = {};
        this.createBooking({ data: data });
      }
      this.submitted = false;
      this.event = {};
    },

    // eslint-disable-next-line no-unused-vars
    editSubmit(e) {
      this.submit = true;
      this.$v.$touch();
      if (this.$v.editevent.$invalid) {
        return;
      } else {
        var data = this.editevent;
        data.meetingRoomId = data.id;
        data.id = this.selectedEventId;
        this.updateBooking({ data: data });
        this.eventModal = false;
      }
    },

    deleteSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger ml-2",
          cancelButton: "btn btn-primary ml-2"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.value) {
          this.deleteBooking({ id: this.selectedEventId });
          this.eventModal = false;
        }
      });

    },

    dateClicked(info) {
      this.newEventData = info;
      this.showModal = true;
    },

    async editEvent(info) {
      this.edit = info.event;

      var message = "";
      if (this.edit && this.edit.id) {
        await this.getBooking({ id: this.edit.id });
        var newEvent = this.$store.state.meetingRoomBooking.list.data.filter(item => item.id == this.edit.id);

        this.editevent.title = newEvent[0].title;
        this.editevent.start = moment(newEvent[0].data_start, 'hh:mm A').format('HH:mm');
        this.editevent.date = (newEvent[0].data_date);
        this.editevent.end = moment(newEvent[0].data_end, 'hh:mm A').format('HH:mm');
        this.editevent.detail = newEvent[0].detail;
        this.editevent.id = newEvent[0].meetingRoomId;
        this.editevent.bookingBy = this.$store.state.meetingRoomBooking.details.user != null ? this.$store.state.meetingRoomBooking.details.user.name : this.$store.state.meetingRoomBooking.details.internal_user.first_name + " " + this.$store.state.meetingRoomBooking.details.internal_user.last_name;

        this.selectedEventId = info.event.id;
        this.eventModal = true;
      }
      else {
        message = i18n.t("unknownError");
        sharedHelper.alertErrorMessage(message, '1.1.001')
      }
    },

    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.submitted = false;
      this.showModal = false;
      this.event.title = "";
      this.event.date = null;
      this.event.start = null;
      this.event.detail = "";
      this.event.end = null;
      // this.event = {};
    },

    closeModal() {
      this.eventModal = false;

      this.editevent.title = "";
      this.editevent.date = null;
      this.editevent.start = null;
      this.editevent.detail = "";
      this.event.end = null;
      this.selectedEventId = "";

    },

    /**
     * Show list of events
     */
    handleEvents(events) {
      this.currentEvents = events;
    },

  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div style="width:100%; text-align: center;" v-if="status.processiong">
      <b-spinner class="m-2" variant="primary" role="status"></b-spinner>
    </div>
    <div class="row">

      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="app-calendar">
              <FullCalendar ref="fullCalendar" :options="calendarOptions" :key="calendarKey"></FullCalendar>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showModal" title="Add New Meeting" title-class="text-black font-18" body-class="p-3" hide-footer
      @close="hideModal">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="name">Title</label>
              <input id="name" v-model="event.title" type="text" class="form-control" placeholder=""
                :class="{ 'is-invalid': submitted && $v.event.title.$error }" />
              <div v-if="submitted && !$v.event.title.required" class="invalid-feedback">
                This value is required.
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Date</label>
              <b-form-datepicker v-model="event.date" :config="optionsDate" class="mb-2"
                :class="{ 'is-invalid': submitted && $v.event.date.$error }"></b-form-datepicker>
              <div v-if="submitted && !$v.event.date.required" class="invalid-feedback">
                This value is required.
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Start Time</label>
              <date-picker :config="options" v-model="event.start"
                :class="{ 'is-invalid': submitted && $v.event.start.$error }"></date-picker>
              <div v-if="submitted && !$v.event.start.required" class="invalid-feedback">
                This value is required.
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label class="control-label">End Time</label>
              <date-picker :config="options" v-model="event.end"
                :class="{ 'is-invalid': submitted && $v.event.end.$error }"></date-picker>
              <div v-if="submitted && !$v.event.end.required" class="invalid-feedback">
                This value is required.
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Meeting Room</label>
              <select class="custom-select" v-model="event.id"
                :class="{ 'is-invalid': submitted && $v.event.id.$error }">
                <option v-for="room in roomDdl" :value="room.value" v-bind:key="room.value">
                  {{ room.text }}
                </option>
              </select>
              <div v-if="submitted && !$v.event.id.required" class="invalid-feedback">
                This value is required.
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Detail</label>
              <textarea v-model="event.detail" class="form-control" name="textarea" rows="5"></textarea>

            </div>
          </div>
        </div>

        <div class="text-right pt-5 mt-3">
          <b-button variant="light" @click="hideModal">Close</b-button>
          <b-button type="submit" variant="success" class="ml-1">Create</b-button>
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal v-model="eventModal" title="Edit Meeting" title-class="text-black font-18" hide-footer body-class="p-0"
      @close="closeModal">
      <form @submit.prevent="editSubmit">
        <div class="col-12">
          <div class="form-group" style="margin-bottom: 0px;">
            <label class="control-label">Booking By</label>
            <input id="name" v-model="editevent.bookingBy" type="text" class="form-control"
              placeholder="Insert Event name" :class="{ 'is-invalid': $v.editevent.title.$error }" />
          </div>
        </div>

        <div class="p-3">
          <label>Title</label>
          <div class="input-group m-b-15">
            <input id="name" v-model="editevent.title" type="text" class="form-control" placeholder="Insert Event name"
              :class="{ 'is-invalid': $v.editevent.title.$error }" />
            <div v-if="!$v.editevent.title.required" class="invalid-feedback">
              This value is required.
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label class="control-label">Date</label>
            <b-form-datepicker v-model="editevent.date" :config="optionsDate" class="mb-2"
              :class="{ 'is-invalid': $v.editevent.date.$error }"></b-form-datepicker>
            <div v-if="!$v.editevent.date.required" class="invalid-feedback">
              This value is required.
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label class="control-label">Start Time</label>
            <date-picker :config="options" v-model="editevent.start"
              :class="{ 'is-invalid': submitted && $v.editevent.start.$error }"></date-picker>
            <div v-if="!$v.editevent.start.required" class="invalid-feedback">
              This value is required.
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label class="control-label">End Time</label>
            <date-picker :config="options" v-model="editevent.end"
              :class="{ 'is-invalid': submitted && $v.editevent.end.$error }"></date-picker>
            <div v-if="!$v.editevent.end.required" class="invalid-feedback">
              This value is required.
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="form-group">
            <label class="control-label">Meeting Room</label>
            <select class="custom-select" v-model="editevent.id"
              :class="{ 'is-invalid': submitted && $v.editevent.id.$error }">
              <option v-for="room in roomDdl" :value="room.value" v-bind:key="room.value">
                {{ room.text }}
              </option>
            </select>
            <div v-if="submitted && !$v.editevent.id.required" class="invalid-feedback">
              This value is required.
            </div>
          </div>
        </div>

        <div class="p-3">
          <label>Detail</label>
          <div class="input-group m-b-15">
            <textarea v-model="editevent.detail" class="form-control" name="textarea" rows="5"></textarea>
          </div>
        </div>
        <div class="text-right p-3">
          <button type="submit" class="btn btn-success btn-md">
            <i class="fa fa-check"></i> Save
          </button>

          <b-button variant="danger" @click="deleteSubmit"
            style="margin-left: 10px; margin-right:10px">Delete</b-button>
          <b-button variant="light" @click="closeModal">Close</b-button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
<style>
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
  flex-wrap: wrap;
}
</style>